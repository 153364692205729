<template>
	<div class="tab-view">
		<div class="menus">
			<router-link to="/user/userInfo" :class="{ 'menu': true, 'current': menuIndex == 0 }">基本信息维护</router-link>
			<router-link to="/user/updatePassword" :class="{ 'menu': true, 'current': menuIndex == 1 }">修改密码</router-link>
		</div>
		<div class="tabview-content">
           <router-view></router-view>
		</div>
	</div>
</template>

<script>
	export default {
		mounted: function(){
            var path = this.$route.path
            if(path.indexOf('/user/updatePassword') != -1) {
                this.menuIndex = 1
            } else {
                this.menuIndex = 0
            }
		},
		data() {
			return {
                menuIndex: 0,
			}
        },
        watch: {
            $route(to,from){
               if(to.path.indexOf('/user/updatePassword') != -1) { 
                    this.menuIndex = 1
                } else {
                    this.menuIndex = 0
                } 
            }
        }
	}
</script>

<style lang="scss" scoped>
    .tab-view {
		width: 1000px;
		display: flex;
		flex-direction: row;
		margin: 0px auto 40px auto;
		position: relative;
	}

	.tab-view > .menus {
		display: flex;
		flex-direction: column;
		width: 164px;
		margin-right: 10px;
		margin-top: -1px;
	}

	.tab-view > .menus > .menu {
		display: block;
		width: 164px;
		height: 50px;
		margin-top: 1px;
		background-color: #fff;
		line-height: 50px;
		text-align: center;
		font-weight: 400;
		color: #969696;
		font-size: 16px;
	}

	.tab-view > .menus > .menu.current {
		color: #36C4D0;
		font-weight: bold;
		position: relative;
	}

	.tab-view > .menus > .menu.current::after {
		content: '';
		position: absolute;
		right: 10px;
		top: 20px;
		width: 7px;
		height: 10px;
		background-image: url(../../../../assets/imgs/pc/user/img_more@2x.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.tab-view > .tabview-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		min-height: 466px;
		box-sizing: border-box;
	}
</style>
